import DrawerWrapper from '@/components/Drawer/DrawerWrapper.vue';
import { getUserPermissions } from '../routerServices';

const HourlyTasks = () => import(/* webpackChunkName: "HourlyTasks" */ '@/views/Tasks/index.vue');
const HourlyTasksToDo = () => import(/* webpackChunkName: "HourlyTasks" */ '@/views/Tasks/ToDo/index.vue');
const HourlyTasksTaskPage = () => import(/* webpackChunkName: "HourlyTasks" */ '@/views/Tasks/TaskPage/index.vue');

const HourlyTaskDrawer = () => import(/* webpackChunkName: "HourlyTasks" */ '@/views/Tasks/shared/components/TaskDrawer.vue');

const HourlyTaskSubtasks = () => import(/* webpackChunkName: "HourlyTasks" */ '@/views/Tasks/shared/components/TaskDetails/TaskTabs/SubTasks/index.vue');
const HourlyTaskTimeEntries = () => import(/* webpackChunkName: "HourlyTasks" */ '@/views/Tasks/shared/components/TaskDetails/TaskTabs/TimeEntries/index.vue');
const HourlyTaskScreenshots = () => import(/* webpackChunkName: "HourlyTasks" */ '@/views/Tasks/shared/components/TaskDetails/TaskTabs/Screenshots/index.vue');

const getTaskTabRoutes = pageName => {
  return [
    {
      name: `${pageName}.subtasks`,
      path: 'subtasks',
      component: HourlyTaskSubtasks,
      meta: {
        parentRouteName: pageName,
      },
    },
    {
      name: `${pageName}.time-entries`,
      path: 'time-entries',
      component: HourlyTaskTimeEntries,
      meta: {
        parentRouteName: pageName,
      },
    },
    {
      name: `${pageName}.screenshots`,
      path: 'screenshots',
      component: HourlyTaskScreenshots,
      meta: {
        parentRouteName: pageName,
      },
    },
  ];
};

export default [
  {
    name: 'hourly-tasks',
    path: '/tasks',
    meta: { requiresAuth: true },
    component: HourlyTasks,
    beforeEnter: (to, from, next) => {
      const permissions = getUserPermissions();

      if (!permissions.isFreelancer) {
        return next({ name: 'client-home' });
      }

      next();
    },
    redirect: () => ({ name: 'hourly-tasks.to-do' }),
    children: [
      {
        name: 'hourly-tasks.to-do',
        path: '',
        component: HourlyTasksToDo,
        children: [
          {
            name: 'hourly-tasks.to-do.task-drawer',
            path: 'd/:id',
            props: route => ({ taskId: route.params.id }),
            redirect: () => ({ name: 'hourly-tasks.to-do.task-drawer.subtasks' }),
            children: getTaskTabRoutes('hourly-tasks.to-do.task-drawer'),
            component: {
              render(h) {
                const { taskId } = this.$attrs;
                return h(DrawerWrapper, {
                  props: {
                    drawerComponent: HourlyTaskDrawer,
                  },
                  attrs: {
                    taskId,
                  },
                  on: {
                    close: () => {
                      this.$emit('close');
                    },
                  },
                });
              },
            },
          },
        ],
      },
    ],
  },
  {
    name: 'hourly-task',
    path: '/tasks/:id',
    meta: { requiresAuth: true },
    component: HourlyTasksTaskPage,
    redirect: () => ({ name: 'hourly-task.subtasks' }),
    children: getTaskTabRoutes('hourly-task'),
    beforeEnter: (to, from, next) => {
      // TO DO: Implement permissions
      next();
    },
  },
];
