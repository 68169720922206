<template>
  <div class="filter-container">
    <gl-skeleton-loader
      v-if="loading"
      class="loader"
    />
    <div
      v-else
      v-click-outside="handleClose"
      class="base-filter"
      @click.self="handleClose"
    >
      <filters-button
        ref="filtersButton"
        :count="0"
        :is-opened="isOpened"
        :filters="activeFilters"
        @click="handleFilterClick"
      />
      <active-filters
        v-if="showActiveFilters"
        :filters="activeFilters"
        :filters-config="filtersConfig"
        :loading="loading"
        @open="handleActiveFilterChange"
        @remove="handleFilterRemove"
        @click="handleClose"
      />
      <gl-dropdown-transition>
        <filters-data
          v-if="isOpened"
          ref="filtersPopup"
          :filters="filters"
          :filters-config="filtersConfig"
          :selected-index="selectedIndex"
          class="base-filter__data"
          @activeChange="handleActiveFilterChange"
          @change="handleFiltersChange"
        />
      </gl-dropdown-transition>
    </div>
  </div>
</template>

<script>
  import GlDropdownTransition from 'uikit/components/transitions/DropdownTransition.vue';
  import GlSkeletonLoader from 'uikit/components/skeletons/SkeletonLoader.vue';

  import _omit from 'lodash/omit';
  import _keys from 'lodash/keys';
  import _values from 'lodash/values';
  import _pickBy from 'lodash/pickBy';
  import _pick from 'lodash/pick';
  import _isEmpty from 'lodash/isEmpty';
  import _isObject from 'lodash/isObject';
  import _isArray from 'lodash/isArray';

  import FiltersButton from './components/FiltersButton/index.vue';
  import ActiveFilters from './components/ActiveFilters/index.vue';
  import FiltersData from './components/FiltersContent/index.vue';
  import { defaultFiltersConfig } from './constants';

  import { compareByDay, getAllTime } from './helpers/date';

  const defaultFixedPopupConfig = {
    scrollableParentSelector: 'body',
    fixed: false,
  };

  export default {
    components: {
      FiltersButton,
      ActiveFilters,
      FiltersData,
      GlDropdownTransition,
      GlSkeletonLoader,
    },
    model: {
      prop: 'filters',
      event: 'change',
    },
    props: {
      filtersConfig: {
        type: Array,
        default: () => defaultFiltersConfig,
      },
      filters: {
        type: Object,
        default: () => {},
      },
      loading: {
        type: Boolean,
        default: false,
      },
      fixedPopupConfig: {
        type: Object,
        default: () => defaultFixedPopupConfig,
      },
      showActiveFilters: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        isOpened: false,
        selectedIndex: 0,

        scrollableParent: null,
        resizableParent: null,
        resizeObserver: null,
      };
    },
    computed: {
      filtersComputed: {
        get() {
          return this.filters;
        },
        set(val) {
          this.$emit('change', val);
        },
      },
      activeFilters() {
        const appliedFilterKeys = Object.keys(this.filtersComputed).filter(key => {
          const config = this.filtersConfig.find(conf => key === conf.key);

          if (config.date) {
            const defaultValue = config.defaultValue || getAllTime();
            return !compareByDay(this.filtersComputed[key], defaultValue);
          }

          if (config.search) {
            return Boolean(this.filtersComputed[key]);
          }

          return true;
        });

        return _pick(this.filters, appliedFilterKeys);
      },
    },
    beforeDestroy() {
      this.resizeObserver?.disconnect();
      this.scrollableParent?.removeEventListener('scroll', this.repositionPopUp);
    },
    methods: {
      async handleFilterClick() {
        this.isOpened = !this.isOpened;

        await this.$nextTick();

        this.initFixedPopupReposition();
      },
      initFixedPopupReposition() {
        if (this.isOpened && this.fixedPopupConfig.fixed) {
          this.resizableParent = this.$el.closest(this.fixedPopupConfig.scrollableParentSelector);

          if (!this.resizableParent) {
            return;
          }

          this.scrollableParent = this.resizableParent === document.body ? document : this.resizableParent;
          this.resizeObserver = new ResizeObserver(this.repositionPopUp);
          this.resizeObserver.observe(this.resizableParent);
          this.resizeObserver.observe(this.$refs.filtersPopup.$el);
          this.resizeObserver.observe(document.body);

          this.repositionPopUp();
          this.scrollableParent.addEventListener('scroll', this.repositionPopUp);
        }
      },
      handleClose() {
        this.isOpened = false;

        this.resizeObserver?.disconnect();
        this.scrollableParent?.removeEventListener('scroll', this.repositionPopUp);
      },
      handleFiltersChange(filters) {
        this.filtersComputed = _pickBy(filters, value => {
          if (_isObject(value)) {
            return !_isEmpty(value);
          }

          return !!value;
        });
      },
      async handleActiveFilterChange(index) {
        this.isOpened = true;
        this.selectedIndex = index;

        await this.$nextTick();

        this.initFixedPopupReposition();
      },
      handleFilterRemove(filter, config) {
        const filterKey = _keys(filter)[0];
        const filterValue = _values(filter)[0];
        const isFilterArray = _isArray(this.filtersComputed[filterKey]);
        const isFilterObject = config.returnObject;

        const isFilterDate = config.date;

        if (isFilterDate && (config.required || config.defaultValue)) {
          this.filtersComputed = {
            ...this.filtersComputed,
            [filterKey]: config.defaultValue || getAllTime(),
          };
        } else if (isFilterArray) {
          const updatedArray = this.filtersComputed[filterKey].filter(value => {
            if (isFilterObject) {
              return value?.[config.optionsValueKey] !== filterValue[config.optionsValueKey];
            }

            return value !== filterValue;
          });

          if (!updatedArray.length) {
            this.filtersComputed = _omit(this.filtersComputed, filterKey);
          } else {
            this.filtersComputed = { ...this.filtersComputed, [filterKey]: updatedArray };
          }
        } else {
          this.filtersComputed = _omit(this.filtersComputed, filterKey);
        }
      },

      repositionPopUp() {
        if (!this.isOpened) {
          return;
        }

        const { filtersPopup, filtersButton } = this.$refs;
        const {
          top, height, left,
        } = filtersButton.$el.getBoundingClientRect();

        const offset = 8;

        const filtersPopupEl = filtersPopup.$el;

        filtersPopupEl.style.position = 'fixed';

        const listTopPosition = top + height + offset;
        const listLeftPosition = left;
        filtersPopupEl.style.bottom = 'auto';
        filtersPopupEl.style.top = `${listTopPosition}px`;

        if (listTopPosition + filtersPopupEl.offsetHeight > window.innerHeight) {
          filtersPopupEl.style.bottom = `${offset}px`;
          filtersPopupEl.style.top = 'auto';
        }

        filtersPopupEl.style.right = 'auto';
        filtersPopupEl.style.left = `${listLeftPosition}px`;

        if (filtersPopupEl.offsetWidth + listLeftPosition > window.innerWidth) {
          filtersPopupEl.style.right = `${offset}px`;
          filtersPopupEl.style.left = 'auto';
        }
      },
    },
  };
</script>

<style lang="postcss" scoped>
.filter-container {
  width: 100%;
}
.loader {
  @apply bg-foundation-gray-3;
  width: 100%;
  height: 36px;
}
.base-filter {
  @apply flex items-start;
  position: relative;
  flex-wrap: wrap;
  gap: 20px;
  z-index: 21;

  &__data {
    position: absolute;
    top: 40px;
    z-index: 1;

    & >>> .filters-accordions__accordion {
      border: 0;
    }
  }
}
</style>
