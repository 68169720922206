<template>
  <div class="filter-data">
    <search-input
      v-show="isSearch"
      :search="filters.search"
      @input="handleSearchChange"
    />

    <component
      :is="customListComponent"
      v-if="customListComponent"
      :filters="filters"
      :filter-config="filterConfig"
      :label-key="filterConfig.optionsLabelKey"
      :value-key="filterConfig.optionsValueKey"
      :return-object="filterConfig.returnObject"
      @change="updateFilters"
    />

    <date-picker-body
      v-else-if="isDate"
      v-model="dateRange[filterConfig.key]"
      :max-date="maxDate"
      :min-date="minDate"
      :selected-date="selectedDate"
      :presets="presets[filterConfig.key]"
      :disable-presets-before-maxdate="false"
      class="date-picker-body"
      @save="handleDateSave(dateRange[filterConfig.key], filterConfig.key)"
    />

    <options-list
      v-else-if="isOptionsList && !isCategoryOptionsList"
      :filters="filters"
      :filter-config="filterConfig"
      :label-key="filterConfig.optionsLabelKey"
      :value-key="filterConfig.optionsValueKey"
      :return-object="filterConfig.returnObject"
      @change="updateFilters"
    />

    <category-options-list
      v-else-if="isOptionsList && isCategoryOptionsList"
      :filters="filters"
      :filter-config="filterConfig"
      :label-key="filterConfig.optionsLabelKey"
      :value-key="filterConfig.optionsValueKey"
      :return-object="filterConfig.returnObject"
      @change="updateFilters"
    />
  </div>
</template>

<script>
  import _isEmpty from 'lodash/isEmpty';
  import _isNil from 'lodash/isNil';

  import moment from 'moment-timezone';
  import SearchInput from './components/SearchInput.vue';
  import DatePickerBody from './components/DatePickerBody.vue';
  import OptionsList from './components/OptionsList.vue';

  import CategoryOptionsList from './components/CategoryOptionsList.vue';

  import { dateOptions } from './constants';

  const DEFAULT_DATE = {
    start: moment.utc(new Date(0)).toDate(),
    end: moment.utc(Date.now()).endOf('day').toDate(),
  };

  const MIN_DATE = new Date(0);

  export default {
    components: {
      SearchInput,
      DatePickerBody,
      OptionsList,
      CategoryOptionsList,
    },
    props: {
      filterConfig: {
        type: Object,
        required: true,
      },
      filters: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        dateRange: {
          [this.filterConfig.key]: this.filters[this.filterConfig.key] || this.filters.date || DEFAULT_DATE,
        },
        minDate: MIN_DATE,
        presets: {
          [this.filterConfig.key]: this.filterConfig.presets || dateOptions,
        },
        selectedDate: {},
      };
    },
    computed: {
      isSearch() {
        return this.filterConfig.search;
      },
      isDate() {
        return this.filterConfig.date;
      },
      maxDate() {
        return _isNil(this.filterConfig.maxDate) ? moment.utc().endOf('day').toDate() : this.filterConfig.maxDate;
      },
      customListComponent() {
        return this.filterConfig.customOptionsListComponent;
      },
      isOptionsList() {
        return this.filterConfig.options?.length || this.filterConfig.optionsFetcher;
      },
      isCategoryOptionsList() {
        return this.filterConfig.withCategories;
      },
    },
    watch: {
      'filterConfig.key'(value) {
        if (!this.isDate) return;
        this.dateRange = {
          [value]: this.filters[value] || this.filters.date || DEFAULT_DATE,
        };
        this.presets = {
          [value]: this.filterConfig.presets || dateOptions,
        };
      },
      filters: {
        deep: true,
        handler(newFilters) {
          if (!this.isDate) return;
          if (_isEmpty(newFilters[this.filterConfig.key])) {
            this.dateRange[this.filterConfig.key] = DEFAULT_DATE;
          } else {
            this.dateRange[this.filterConfig.key] = newFilters[this.filterConfig.key];
          }
        },
      },
    },
    created() {
      const date = this.filters[this.filterConfig.key] || this.filters.date;
      this.selectedDate = _isNil(date) ? {} : date;
    },
    methods: {
      updateFilters(newFilters) {
        this.$emit('change', { ...this.filters, ...newFilters });
      },
      handleSearchChange(search) {
        this.updateFilters({ [this.filterConfig.key]: search });
      },
      handleDateChange(dateRange) {
        this.dateRange[this.filterConfig.key] = dateRange.value;
      },
      handleDateSave(dateRange, filterKey) {
        this.updateFilters({ [filterKey]: dateRange });
      },
    },
  };
</script>

<style lang="postcss" scoped>
  .filter-data {
    @apply flex flex-col border-1 border-foundation-gray-4;
    min-width: 600px;
    max-height: 500px;
    overflow-y: auto;
    border-width: 0 0 0 1px;
    border-radius: 0 12px 12px 0;
  }
</style>
